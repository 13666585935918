export default {
  isBrokerSubscribed(state) {
    return state.chat?.is_broker_subscribed
  },
  isMessagesLoading(state) {
    return state.chat.isMessagesLoading
  },
  getNewMessage(state) {
    return state.chat.newMessage
  },
}
