
import Vue from 'vue'
import ClickOutside from 'vue-click-outside'
import { getImagesAsset } from '@/config/util'
import { getDemoAsset, checkDomain } from '@/config/util'
import subscriptionStatusMixin from '@/mixins/subscriptionStatusMixin'
import SvgIcon from '@/components/base/SvgIcon/SvgIcon.vue'
import BlockPopupInvite from '@/components/blocks/BlockPopup/BlockPopupInvite.vue'
import { mapState } from 'vuex'

interface Data {
  placeholderImage: string
  notificationImage: string
  notificationNum: number
  active: boolean
  pageCheck: boolean
  showPopup: boolean
  ExplanationVideoPopupActive: boolean
}

export default Vue.extend({
  components: {
    SvgIcon,
    BlockPopupInvite,
  },

  directives: {
    ClickOutside,
  },
  mixins: [subscriptionStatusMixin],

  data(): Data {
    return {
      placeholderImage: getImagesAsset('placeholders/user_placeholder.png'),
      notificationImage: getDemoAsset('profile/notification_icon.jpg'),
      notificationNum: 17,
      active: false,
      pageCheck: false,
      showPopup: false,
      ExplanationVideoPopupActive: false,
    }
  },

  created() {
    this.user
  },

  methods: {
    async handleManageMySubscription() {
      const response = await this.$store.dispatch('getSparkUrl', {
        return_url: `${window.location.origin}${window.location.pathname}`,
      })
      window.location.href = response.data.data.sparkLogin.success
    },

    clickOutside(event) {
      // If target is not video, then close popup
      if (!this.$refs.video.contains(event.target)) {
        this.ExplanationVideoPopupActive = false
      }
    },

    ExplanationVideo() {
      this.ExplanationVideoPopupActive = true
      this.$store.dispatch('explanationVideoFromhamburgerMenuClicked', false)
    },

    popupClose() {
      this.ExplanationVideoPopupActive = false
      this.$store.dispatch('explanationVideoFromhamburgerMenuClicked', false)
    },

    /**
     * @Author Nikola Popov
     * @description
     * logut user
     * remove local storage and redirect to login route
     */
    logout() {
      this.$store.dispatch('logout').then(() => {
        if (process.env.VUE_APP_GTM_ENVIRONMENT === 'production') {

          //Google Analytics userID
          var auth = JSON.parse(localStorage.getItem('auth'))

          window['dataLayer'] = window['dataLayer'] || []
          window['dataLayer'].push({
            'event': 'logout',
            'userId': auth?.user?.id,
          })
        }

        // this.$store.commit('INCREMENT_UNSEEN_MESSAGES', {})
        localStorage.removeItem('auth')
        localStorage.removeItem('chatbot')
        window.location.href = "/login"

      })

    },
    hide() {
      this.active = false
    },

    /**
     * @Author Nikola Popov
     * @description
     * remove refresh token on signup page and redirect to login
     */
    clearToken() {
      if (this.$router.currentRoute.path === '/sign-up/lender/2') {
        const token = localStorage.getItem('tempToken')
        if (token !== null) {
          localStorage.removeItem('tempToken')
          //TODO in Pre MVP push to Landing page
          //TODO for MVP push to login
          //this.$router.push('/login');
        }
      }
    },
    openPopup() {
      this.showPopup = true
    },
    closePopup() {
      this.showPopup = false
    },
    goTo() {
      if (this.$route.path !== '/profile') {
        this.$router.push({ path: '/profile' })
      }
    },

    // Checks if there is a variable in .env file and returns true if so
    logoChange() {
      return checkDomain()
    },
  },


  // if user logedin show header and footer
  computed: {
    isTrialing() {
      return this.$store.getters.subscriptionIsTrial
    },
    loggedIn() {
      return this.$store.getters.getLoggedIn
    },
    user() {
      return this.$store.getters.getUser
    },
    image() {
      return this.$store.getters.getProfileImage
    },
    subscription() {
      return this.$store.state.Broker.subscription
    },
    subscriptionName() {
      let plan = this.$store.state.Broker.subscription
      if (this.isActiveSubscription(plan?.status)) {
        return plan?.name
      } else if (this.isTrialingSubscription(plan.status)) {
        return `${plan?.name} - Free`
      } else {
        return 'Free tier'
      }
    },
    daysRemaining() {
      let today = new Date()
      var endsAt = new Date(this.subscription?.ends_at)

      let diff = endsAt.getTime() - today.getTime()
      let days = Math.round(diff / (1000 * 3600 * 24))

      return days > 1 ? `${days} days` : `${days} day`
    },

    ...mapState({
      non_beta_user: state => state['User'].non_beta_user,
      unseenMessages: state => state['Chat'].chat.unseenMessages,
    }),

    explanationVideoFromhamburgerMenuClicked() {
      return this.$store.getters.getExplanationVideoClicked
    },

    // Returns a proper video link for broker or lender, for explanation video popup
    userRoleSpecificVideoLink() {
      return this.$store.getters.getUser.role === 'broker' ? 'https://financelobby-production.s3.amazonaws.com/assets/Broker.mp4' : 'https://financelobby-production.s3.amazonaws.com/assets/FinanceLobby051222.mp4'
    },

    userRoleSpecificMessage() {
      return this.$store.getters.getUser.role === 'broker' ? 'Your average response time will soon be visible to lenders, showcasing your responsiveness and commitment. Timely replies to messages will enhance your reputation and increase the visibility of your deals.' : 'Your average response time will soon be visible to brokers, showcasing your responsiveness and commitment. Timely replies to messages will enhance your reputation and attract more opportunities.'
    },

    showAlert() {
      if(this.$route.name === 'Lender Deals' || this.$route.name === 'Broker Deals' || this.$route.name === 'Broker Quotes' || this.$route.name === 'Profile' || this.$route.name === 'All Messages') {
        return true
      }else {
        return false
      }
    },
  },

  beforeMount() {
    // this.checkPages();
  },

  mounted() {
    if (this.loggedIn) {
      this.$store.dispatch('getUnseenMessages')
    }

    this.$root.$on('logout-beta', function () {
      this.logout()
    })
  },
})
